import Vue from 'vue';
import Router from 'vue-router';
import * as c from '@model/const/sites';

const viewFactory = (siteID, view) => () => import(`./${siteID}/${view}/container.vue`);

const routes = {
    [c.SITE_ID_WWW]: [
        // Inapp
        {
            name: 'inapp',
            path: '/:locale/inapp/*',
            component: viewFactory(c.SITE_ID_WWW, 'inapp/pages'),
            pathToRegexpOptions: { strict: true },
        },
        // Blog
        {
            name: 'blog-home',
            path: '/:locale/blog/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/home'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-home-page',
            path: '/:locale/blog/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/home'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-post-draft',
            path: '/:locale/blog/posts/:slug/draft-:draftKey/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/post'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-post',
            path: '/:locale/blog/posts/:slug/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/post'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-category',
            path: '/:locale/blog/categories/:category/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/category'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-category-page',
            path: '/:locale/blog/categories/:category/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/category'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-tag',
            path: '/:locale/blog/tags/:tag/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/tag'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-tag-page',
            path: '/:locale/blog/tags/:tag/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/tag'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-authors',
            path: '/:locale/blog/authors/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/authors'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-author-posts',
            path: '/:locale/blog/authors/:author/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/author-posts'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-author-posts-page',
            path: '/:locale/blog/authors/:author/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/author-posts'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-search',
            path: '/:locale/blog/search/',
            component: viewFactory(c.SITE_ID_WWW, 'blog/search'),
            pathToRegexpOptions: { strict: true },
        },
        // Blog CPC (TRU Security)
        {
            name: 'blog-cpc-home',
            path: '/:locale/cyber-protection-center/',
            component: viewFactory(c.SITE_ID_WWW, 'cpc/home'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-cpc-about',
            path: '/:locale/cyber-protection-center/about/',
            component: viewFactory(c.SITE_ID_WWW, 'cpc/about'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-cpc-home-page',
            path: '/:locale/cyber-protection-center/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'cpc/home'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-cpc-post-draft',
            path: '/:locale/cyber-protection-center/posts/:slug/draft-:draftKey/',
            component: viewFactory(c.SITE_ID_WWW, 'cpc/post'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-cpc-post',
            path: '/:locale/cyber-protection-center/posts/:slug/',
            component: viewFactory(c.SITE_ID_WWW, 'cpc/post'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-cpc-authors',
            path: '/:locale/cyber-protection-center/authors/',
            component: viewFactory(c.SITE_ID_WWW, 'cpc/authors'),
            pathToRegexpOptions: { strict: true },
        },
        {
            // WARN: These should go before `blog-cpc-category` because of overlapping path prefix
            name: 'blog-cpc-news',
            path: '/:locale/cyber-protection-center/categories/news/',
            component: viewFactory(c.SITE_ID_WWW, 'cpc/news'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-cpc-news-page',
            path: '/:locale/cyber-protection-center/categories/news/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'cpc/news'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-cpc-category',
            path: '/:locale/cyber-protection-center/categories/:category/',
            component: viewFactory(c.SITE_ID_WWW, 'cpc/category'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'blog-cpc-category-page',
            path: '/:locale/cyber-protection-center/categories/:category/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'cpc/category'),
            pathToRegexpOptions: { strict: true },
        },
        // PR
        {
            name: 'pr-home-page',
            path: '/:locale/pr/',
            component: viewFactory(c.SITE_ID_WWW, 'pr-home-page'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'pr-home-page-page',
            path: '/:locale/pr/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'pr-home-page'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'pr-media-contacts',
            path: '/:locale/pr/media-sources/',
            component: viewFactory(c.SITE_ID_WWW, 'pr-media-contacts'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'pr-by-year',
            path: '/:locale/pr/:year/',
            component: viewFactory(c.SITE_ID_WWW, 'pr-by-year'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'pr-by-year-page',
            path: '/:locale/pr/:year/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'pr-by-year'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'pr-press-release-draft',
            path: '/:locale/pr/:year(\\d+)/:slug/draft-:draftKey/',
            component: viewFactory(c.SITE_ID_WWW, 'pr-press-release'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'pr-press-release',
            path: '/:locale/pr/:year(\\d+)/:slug/',
            component: viewFactory(c.SITE_ID_WWW, 'pr-press-release'),
            pathToRegexpOptions: { strict: true },
        },
        // RC
        {
            name: 'resource-center-home',
            path: '/:locale/resource-center/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-home'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'resource-center-category',
            path: '/:locale/resource-center/category/:slug/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-category'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'resource-center-category-page',
            path: '/:locale/resource-center/category/:slug/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-category'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'resource-center-audience',
            path: '/:locale/resource-center/audience/:slug/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-audience'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'resource-center-audience-page',
            path: '/:locale/resource-center/audience/:slug/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-audience'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'resource-center-tag',
            path: '/:locale/resource-center/tag/:slug/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-tag'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'resource-center-tag-page',
            path: '/:locale/resource-center/tag/:slug/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-tag'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'resource-center-resource-draft',
            path: '/:locale/resource-center/resource/:slug/draft-:draftKey/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-resource'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'resource-center-resource',
            path: '/:locale/resource-center/resource/:slug/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-resource'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'resource-center-search',
            path: '/:locale/resource-center/search/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-search'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'resource-center-search-page',
            path: '/:locale/resource-center/search/page/:page/',
            component: viewFactory(c.SITE_ID_WWW, 'resource-center-search'),
            pathToRegexpOptions: { strict: true },
        },
        // Errors
        {
            name: 'http-error-404',
            path: '/:locale/http-errors/404/',
            component: viewFactory(c.SITE_ID_WWW, 'http-error-404'),
            pathToRegexpOptions: { strict: true },
        },
        // Pages && catch-all
        {
            name: 'pages-preview',
            path: '/:locale/preview/:previewId/',
            component: viewFactory(c.SITE_ID_WWW, 'pages'),
        },
        {
            name: 'pages-page',
            path: '/:locale/:pathMatch(.*)*/',
            component: viewFactory(c.SITE_ID_WWW, 'pages'),
        },
    ],
    [c.SITE_ID_TRUEIMAGE]: [
        // NOTES:
        // - TI site is basically cloned from SITE_ID_WWW, but with a small subset of views only – this is the scope of Phase 1.
        // - While these views are (almost) identical, it's not recommended to reuse original ones, because more changes are coming.
        {
            name: 'http-error-404',
            path: '/:locale/http-errors/404/',
            component: viewFactory(c.SITE_ID_TRUEIMAGE, 'http-error-404'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'pages-preview',
            path: '/:locale/preview/:previewId/',
            component: viewFactory(c.SITE_ID_TRUEIMAGE, 'pages'),
        },
        {
            name: 'pages-page',
            path: '/:locale/:pathMatch(.*)*/',
            component: viewFactory(c.SITE_ID_TRUEIMAGE, 'pages'),
        },
    ],
    [c.SITE_ID_EVENTS]: [
        {
            name: 'archive',
            path: '/archive/',
            component: viewFactory(c.SITE_ID_EVENTS, 'archive'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'archive-localized',
            path: '/:locale/archive/',
            component: viewFactory(c.SITE_ID_EVENTS, 'archive'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'category',
            path: '/category/:category/',
            component: viewFactory(c.SITE_ID_EVENTS, 'category'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'category-localized',
            path: '/:locale/category/:category/',
            component: viewFactory(c.SITE_ID_EVENTS, 'category'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'event',
            path: '/event/:slug/:key?/',
            component: viewFactory(c.SITE_ID_EVENTS, 'event'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'event-localized',
            path: '/:locale/event/:slug/:key?/',
            component: viewFactory(c.SITE_ID_EVENTS, 'event'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'http-error-404',
            path: '/http-errors/404/',
            component: viewFactory(c.SITE_ID_EVENTS, 'http-error-404'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'http-error-404-localized',
            path: '/:locale/http-errors/404/',
            component: viewFactory(c.SITE_ID_EVENTS, 'http-error-404'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'home',
            path: '/',
            component: viewFactory(c.SITE_ID_EVENTS, 'home'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'home-localized',
            path: '/:locale/',
            component: viewFactory(c.SITE_ID_EVENTS, 'home'),
            pathToRegexpOptions: { strict: true },
        },
    ],
    [c.SITE_ID_SCS]: [
        {
            name: 'http-error-404',
            path: '/http-errors/404/',
            component: viewFactory(c.SITE_ID_SCS, 'http-error-404'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'pages-preview',
            path: '/preview/:previewId/',
            component: viewFactory(c.SITE_ID_SCS, 'pages'),
        },
        {
            name: 'pages-page',
            path: '/:pathMatch(.*)*/',
            component: viewFactory(c.SITE_ID_SCS, 'pages'),
        },
    ],
    [c.SITE_ID_SOLUTIONS]: [
        {
            name: 'http-error-404',
            path: '/:locale/http-errors/404/',
            component: viewFactory(c.SITE_ID_SOLUTIONS, 'http-error-404'),
            pathToRegexpOptions: { strict: true },
        },
        {
            path: '/:locale/',
            name: 'solutions-home',
            component: viewFactory(c.SITE_ID_SOLUTIONS, 'home'),
        },
        {
            path: '/:locale/page/:page/',
            name: 'solutions-home-page',
            component: viewFactory(c.SITE_ID_SOLUTIONS, 'home'),
        },
        {
            path: '/:locale/category/:category/:subcategory?/',
            name: 'solutions-home-category',
            component: viewFactory(c.SITE_ID_SOLUTIONS, 'home'),
        },
        {
            path: '/:locale/category/:category/page/:page/',
            name: 'solutions-home-category-page',
            component: viewFactory(c.SITE_ID_SOLUTIONS, 'home'),
        },
        {
            path: '/:locale/category/:category/:subcategory/page/:page/',
            name: 'solutions-home-subcategory-page',
            component: viewFactory(c.SITE_ID_SOLUTIONS, 'home'),
        },
        {
            path: '/:locale/integrations/:slug/',
            name: 'solutions-integration',
            component: viewFactory(c.SITE_ID_SOLUTIONS, 'integration'),
        },
        // "Catch all" path to capture & redirect old URLs scheme
        // TODO: this is probably obsolete after a year
        {
            path: '/:locale/*',
            name: 'solutions-catch-all',
            component: viewFactory(c.SITE_ID_SOLUTIONS, 'catch-all'),
        },
    ],
    [c.SITE_ID_DEVELOPER]: [
        {
            name: 'http-error-404',
            path: '/:locale/http-errors/404/',
            component: viewFactory(c.SITE_ID_DEVELOPER, 'http-error-404'),
            pathToRegexpOptions: { strict: true },
        },
        {
            name: 'pages-preview',
            path: '/:locale/preview/:previewId/',
            component: viewFactory(c.SITE_ID_DEVELOPER, 'pages'),
        },
        {
            name: 'pages-page',
            path: '/:locale/:pathMatch(.*)*/',
            component: viewFactory(c.SITE_ID_DEVELOPER, 'pages'),
        },
    ],
    [c.SITE_ID_ACADEMY]: [
        {
            name: 'http-error-404',
            path: '/:locale/http-errors/404/',
            component: viewFactory(c.SITE_ID_ACADEMY, 'http-error-404'),
            pathToRegexpOptions: { strict: true },
        },
        {
            path: '/:locale/',
            name: 'academy-home',
            component: viewFactory(c.SITE_ID_ACADEMY, 'home'),
        },
        {
            path: '/:locale/catalogue/',
            name: 'academy-catalogue',
            component: viewFactory(c.SITE_ID_ACADEMY, 'catalogue'),
        },
        {
            path: '/:locale/catalogue/:slug/',
            name: 'academy-catalogue-course',
            component: viewFactory(c.SITE_ID_ACADEMY, 'course'),
        },
    ],
    [c.SITE_ID_TOOLBOX]: [
        {
            name: 'builder-page',
            path: '/slices-builder/',
            component: viewFactory(c.SITE_ID_TOOLBOX, 'builder'),
            pathToRegexOptions: { string: true },
        },
    ],
};

Vue.use(Router);

export function RouterFactory(context) {
    let currentRoutes = routes[context.public.siteID] || [];

    // Hack routes list to leave only page 404 alive. See @model/ssr/context.ts for explanation
    if (context.public.isNotFoundTrap) {
        const route404 = currentRoutes.find((r) => r.name === 'http-error-404');
        const isLocalized = currentRoutes.some((r) => r.path.startsWith('/:locale/'));

        const trap = {
            ...route404,
            path: isLocalized ? '/:locale/*' : '/*',
            pathToRegexpOptions: {},
        };

        currentRoutes = [trap];
    }

    return new Router({
        mode: 'history',
        routes: currentRoutes,
    });
}
